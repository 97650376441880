import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainRoute from "./router/MainRoute";
import { ToastContainer } from "react-toastify";
import { persistor, store } from "./app/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import SidebarState from "./app/context/sidebarOpen/sidebarState";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SidebarState>
            <ToastContainer autoClose={2000} />
            <MainRoute />
          </SidebarState>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
