import React, { useEffect, useState } from "react"
import Headerbar from "../../components/headerbar/Headerbar"
import { Button, Grid, IconButton } from "@mui/material"
import "./OLPendingActions.scss"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import RemoveRedEye from "@mui/icons-material/RemoveRedEye"
import { formatDate2 } from "../../../utility/dateFormatter/dateFormatter"
import { getRequest, postRequestWithHeaders } from "../../../services/api/axios_api_Request"
import { toast } from "react-toastify"
import { formatToIndianRupees } from "../../../utility/currencyFormatter/currencyFormatter"
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal"
import axios from "axios"

export const OLPendingActions = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [letterData, setLetterData] = useState([])
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)

  //functions for Confirm Modal
  const handleClose = () => setOpen(false)
  // Custom styled button
  const GreyButton = styled(Button)(({ theme }) => ({
    color: "grey",
    borderColor: "grey",
    "&:hover": {
      borderColor: "darkgrey",
    },
  }))

  const getLetterData = async () => {
    try {
      const res = await getRequest("/pending-for-action")
      let data = await res?.data
      data = data?.map((item) => {
        return {
          ...item,
          Generated_Date: formatDate2(item.Generated_Date),
        }
      })
      console.log("the ------->>>>>>>>>>>>>>", data)
      setLetterData(data)
    } catch (error) {
      console.log("Error fetching All Letters Data", error)
    }
  }

  useEffect(() => {
    getLetterData()
  }, [])

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection)
    console.log("inside handleSelectionChange", selection)
  }

  const sendSelectedRows = async (actionType) => {
    try {
      console.log("inside sendSelectedRows")
      let apiEndpoint = ""
      if (actionType === "approve") {
        apiEndpoint = "approve_letter"
      } else if (actionType === "revoke") {
        apiEndpoint = "revoke_letter"
      } else if (actionType === "delete") {
        apiEndpoint = "delete_row_data"
      }

      const response = await postRequestWithHeaders(
        apiEndpoint,
        { selectedRows },
        {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        }
      )
      console.log("response status........", response)
      if (response.status === "success") {
        if (actionType === "approve") {
          toast.success("Selected letters approved successfully")
        } else if (actionType === "revoke") {
          toast.success("Selected rows revoked successfully")
        } else if (actionType === "revoke") {
          toast.success("Selected rows revoked successfully")
        } else if (actionType === "delete") {
          toast.success("Selected rows deeleteed successfully")
        }
        console.log("response from approve/revoke letter api", response.data)
        getLetterData()
        // Update UI or fetch data again, if needed
      } else {
        toast.error("Error performing action")
      }
      setOpen(false)
    } catch (error) {
      toast.error("Failed to perform action")
      console.log("Error sending the selected rows", error)
    }
  }

  // Button click handlers
  const handleApproveButtonClick = () => {
    setMessage("approve")
    console.log(selectedRows.length)
    if (!selectedRows.length == 0) setOpen(true)
  }

  const handleRevokeButtonClick = () => {
    setMessage("revoke")
    if (!selectedRows.length == 0) setOpen(true)
  }
  const handleApproveDeleteClick = () => {
    setMessage("delete")
    if (!selectedRows.length == 0) setOpen(true)
  }
 
  const renderPreviewButton = (params) => {
    const handleButtonClick = (event) => {
      event.stopPropagation(); // Prevent row click event from triggering
      console.log("Preview button clicked for row:", params.row.id);
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/preview_offer_letter`, { letter_id: params.row.id })
      .then((response) => {
          if (response.data.url) {
              const previewURL = response.data.url;
              window.open(previewURL, "_blank");  // Open the URL in a new tab
          } else {
              console.error("Preview URL not found");
          }
      })
      .catch((error) => {
          console.error("Error previewing template:", error);
      });
    };
  
    return (
      <div>
        <IconButton onClick={handleButtonClick}>
          <RemoveRedEye color="primary" />
        </IconButton>
      </div>
    );
  };
  

const rows = letterData?.map((item) => ({
  id: item.letter_id, // Ensure letter_id is mapped to id
  name: item.name,
  role: item.position_level,
  template: item.template_name.replace(" Template", ""),
  location: item.location,
  generatedOn: item.generated_date,
}));


  // Define columns for DataGrid
  const columns = [
    { field: "id", headerName: "LetterID", flex: 0.7 },
    { field: "name", headerName: "Name", flex: 1.1 },
    { field: "role", headerName: "Role", flex: 1.3 },
    { field: "template", headerName: "Template", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "generatedOn", headerName: "Generated On", flex: 1 },
    {
      field: "action",
      headerName: "Preview",
      flex: 0.7,
      renderCell: renderPreviewButton,
      align: "center",
      headerAlign: "center",
    },
  ]

  return (
    <>
      <Headerbar />
      <Grid className="ol-pending-actions">
        <Grid className="grid-container-main">
          <Grid container className="grid-container">
            <Grid item xs={6} md={12} className="data-grid-table">
              <DataGrid
                rows={rows}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: null,
                }}
                hideFooterPagination // Hide footer pagination controls
                hideFooter // Hide footer
                checkboxSelection // Enable checkbox selection
                onRowSelectionModelChange={(newSelection) => {
                  handleSelectionChange(newSelection)
                }}
                componentsProps={{
                  header: {
                    sx: {
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                        backgroundColor: "#4b4b4b", // Dark grey background for header row
                        color: "#fff", // White text color for header row
                        fontWeight: "bold", // Bold font for header row
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#eee", // Apply to individual header cells
                        color: "black",
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="data-grid-buttons">
            <Grid item xs={8} className="button button0">
              {/* <Button variant="contained">Contained</Button> */}
            </Grid>
            <ConfirmModal
              open={open}
              handleClose={handleClose}
              message={message}
              sendSelectedRows={sendSelectedRows}></ConfirmModal>
            <Grid item xs={4} container className="button-group">
              <Grid item xs={"auto"} className="button button1">
                <Button variant="outlined" color="success" onClick={handleApproveButtonClick}>
                  Approve
                </Button>
              </Grid>
              <Grid item xs={"auto"} className="button button3">
                <Button variant="outlined" color="error" onClick={handleRevokeButtonClick}>
                  Revoke
                </Button>
              </Grid>
              <Grid item xs={"auto"} className="button button4">
                <GreyButton variant="outlined" onClick={handleApproveDeleteClick}>
                  Delete
                </GreyButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}