import { combineReducers } from "redux";
import { getemployeeDataReducer } from "./Admin/manage user/Reducer/manageEmployeeReducer";
import { getOverviewDataReducer } from "./overViewData/Reducer/overviewReducer";
import { getrolesDataReducer } from "./getRoles/Reducer/manageEmployeeReducer";
import { getClientNamesReducer } from "./getClientNames/Reducer/getClientNamesReducer";
export const reducers = combineReducers({
  manageEmployeeGet: getemployeeDataReducer,
  overviewReducer: getOverviewDataReducer,
  rolesReducer: getrolesDataReducer,
  clientNameReducer: getClientNamesReducer
});
