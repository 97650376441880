import * as React from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import "./ConfirmModal.scss"
import { Grid } from "@mui/material"

export default function ConfirmModal({ open, handleClose, message, sendSelectedRows }) {
  React.useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
    return () => {
      document.body.classList.remove("modal-open")
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Grid className="modal-main">
        <Grid className="modal-title">Are you sure to {message} the selected letters?</Grid>
        <Grid className="buttons-group">
          <Button className="buttons" variant="outlined" color="success" onClick={() => sendSelectedRows(message)}>
            {message}
          </Button>
          <Button className="buttons" variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
