import React, { useEffect, useState } from "react";
import Headerbar from "../../components/headerbar/Headerbar";
import { Button, Grid, IconButton, Pagination } from "@mui/material";
import "./OLProcessedActions.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit, Preview } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { getRequest, postRequestWithHeaders } from "../../../services/api/axios_api_Request";
import { formatDate2 } from "../../../utility/dateFormatter/dateFormatter";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

export const OLProcessedActions = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [letterData, setLetterData] = useState([]);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const renderPreviewButton = (params) => {
    const handleButtonClick = (event) => {
      event.stopPropagation(); // Prevent row click event from triggering
      console.log("Preview button clicked for row:", params.row.id);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/preview_offer_letter`, { letter_id: params.row.id })
        .then((response) => {
          if (response.data.url) {
            const previewURL = response.data.url;
            window.open(previewURL, "_blank"); // Open the URL in a new tab
          } else {
            console.error("Preview URL not found");
          }
        })
        .catch((error) => {
          console.error("Error previewing template:", error);
        });
    };

    return (
      <div>
        <IconButton onClick={handleButtonClick}>
          <RemoveRedEye color="primary" />
        </IconButton>
      </div>
    );
  };

  const rows = letterData?.map((item) => ({
    id: item.letter_id,
    name: item.name,
    role: item.position_level,
    template: item.template_name.replace(" Template", ""),
    location: item.location,
    action_date: item.action_date,
    letter_status: item.letter_status,
  }));

  // Define columns for DataGrid
  const columns = [
    { field: "id", headerName: "LetterID", flex: 0.7 },
    { field: "name", headerName: "Name", flex: 1.1 },
    { field: "role", headerName: "Role", flex: 1.3 },
    { field: "template", headerName: "Template", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "action_date", headerName: "Action Date", flex: 1 },
    { field: "letter_status", headerName: "Status", flex: 0.9 },
    {
      field: "action",
      headerName: "Preview",
      flex: 0.7,
      renderCell: renderPreviewButton,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
    console.log("inside handleSelectionChange, selected rows =>", selection);
  };

  const sendSelectedRows = async (actionType) => {
    try {
      console.log(`inside sendSelectedRows for ${actionType}`);
      let apiEndpoint = "";
      if (actionType === "email") {
        apiEndpoint = "send_offer_email";
      } else if (actionType === "download") {
        apiEndpoint = "download_offer_letter";
      } else if (actionType === "drop") {
        apiEndpoint = "dashboard/drop-letter";
      }
      console.log("motemote", selectedRows);

      const res = await postRequestWithHeaders(
        apiEndpoint,
        { selectedRows },
        {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
          responseType: "blob", // Set responseType to 'blob' to handle file download
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      
      const status = res.headers && res.headers["x-status"]; // Ensure headers exist before reading
      console.log("selected rows", selectedRows);
      console.log("header status", status);
      if (status == "success") {
        const url = window.URL.createObjectURL(res.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "offer_letters.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (res?.status === "success") {
        // Check for status success
        console.log("inside success of response");
        if (actionType === "email") {
          toast.success("Selected letters emailed successfully");
        } else if (actionType === "download") {
          toast.success("Selected letters downloaded successfully");
          let url;
          if (res.url) {
            console.log("inside iff");
            url = res.url; // Assuming `res.url` contains the pre-signed URL
          } else {
            console.log("inside else");
            url = window.URL.createObjectURL(new Blob([res.data]));
          }

          const link = document.createElement("a");
          link.href = url;
          let fileName = "downloaded_file.pdf"; // You can customize the file name
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else if (actionType === "drop") {
          toast.success("Selected rows dropped successfully");
          getLetterData();
        }
      } else {
        toast.error("Error performing action");
      }
      setOpen(false);
    } catch (error) {
      toast.error("Failed to perform action");
      console.log("Error sending the selected rows", error);
    }
  };

  // Button click handlers
  const handleEmailButtonClick = () => {
    setMessage("email");
    if (!selectedRows.length == 0) setOpen(true);
  };

  const handleDownloadButtonClick = () => {
    setMessage("download");
    if (!selectedRows.length == 0) setOpen(true);
  };
  const handleDropButtonClick = () => {
    setMessage("drop");
    if (!selectedRows.length == 0) setOpen(true);
  };

  const getLetterData = async () => {
    try {
      const res = await getRequest("/ol-processed");
      let data = await res?.data;
      data = data?.map((item) => {
        return {
          ...item,
          Action_Date: formatDate2(item.Action_Date),
        };
      });
      console.log("processed data ", data);
      setLetterData(data);
    } catch (error) {
      console.log("Error fetching All Letters Data", error);
    }
  };
  useEffect(() => {
    getLetterData();
  }, []);

  return (
    <>
      <Headerbar />
      <Grid className="ol-processed-actions">
        <Grid className="grid-container-main">
          <Grid container className="grid-container">
            <Grid item xs={6} md={12} className="data-grid-table">
              <DataGrid
                rows={rows}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: null,
                }}
                hideFooterPagination // Hide footer pagination controls
                hideFooter // Hide footer
                checkboxSelection // Enable checkbox selection
                onRowSelectionModelChange={(newSelection) => {
                  handleSelectionChange(newSelection);
                }}
                componentsProps={{
                  header: {
                    sx: {
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                        backgroundColor: "#4b4b4b", // Dark grey background for header row
                        color: "#fff", // White text color for header row
                        fontWeight: "bold", // Bold font for header row
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#eee", // Apply to individual header cells
                        color: "black",
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="data-grid-buttons">
            <Grid item xs={8} className="button button0">
              {/* <Button variant="contained">Contained</Button> */}
            </Grid>
            <ConfirmModal
              open={open}
              handleClose={handleClose}
              message={message}
              sendSelectedRows={sendSelectedRows}></ConfirmModal>
            <Grid item xs={4} container className="button-group">
              <Grid item xs={"auto"} className="button button1">
                <Button variant="outlined" color="primary" onClick={handleEmailButtonClick}>
                  Email
                </Button>
              </Grid>
              <Grid item xs={"auto"} className="button button2">
                <Button variant="outlined" color="success" onClick={handleDownloadButtonClick}>
                  Download
                </Button>
              </Grid>
              <Grid item xs={"auto"} className="button button4">
                <Button variant="outlined" color="error" onClick={handleDropButtonClick}>
                  Drop
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
