import React, { useState } from "react";
import Headerbar from "../../components/headerbar/Headerbar";
import { Button, Grid, IconButton, Pagination } from "@mui/material";
import "./ILPendingActions.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit, Preview } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import axios from "axios";

export const ILPendingActions = () => {
  const [selectionModel, setSelectionModel] = useState([]);

  // Custom styled button
  const GreyButton = styled(Button)(({ theme }) => ({
    color: "grey",
    borderColor: "grey",
    "&:hover": {
      borderColor: "darkgrey",
    },
  }));
  // Function to generate random name
  const getRandomName = () => {
    const names = [
      "Rajinikanth",
      "Kamal Haasan",
      "Vijay",
      "Ajith Kumar",
      "Suriya",
      "Prabhas",
      "Anushka Shetty",
      "Nayanthara",
      "Samantha Akkineni",
      "Trisha Krishnan",
      "Tamannaah Bhatia",
      "Kajal Aggarwal",
      // Add more names as needed
    ];
    return names[Math.floor(Math.random() * names.length)];
  };

  // Function to generate random role
  const getRandomRole = () => {
    const roles = [
      "Trainee Engineer",
      "Data Practice",
      "Python Developer",
      "UI Developer",
    ];
    return roles[Math.floor(Math.random() * roles.length)];
  };

  // Function to generate random location
  const getRandomLocation = () => {
    const locations = ["Pune", "Hyderabad"];
    return locations[Math.floor(Math.random() * locations.length)];
  };

  // Function to generate random date in dd-mmm-yyyy format
  const getRandomDate = () => {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = 2023; // Fixed year for example
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day}-${months[month - 1]}-${year}`;
  };

  const renderPreviewButton = (params) => {
    return (
      <div>
        <IconButton
          children={<RemoveRedEye color="primary"></RemoveRedEye>}
        ></IconButton>
        <IconButton children={<Edit color="primary"></Edit>}></IconButton>
      </div>
    );
  };

  // Function to generate random hiring type
  const getRandomHiringType = () => {
    const hiringTypes = ["Campus-CDAC", "Campus-other", "Lateral"];
    return hiringTypes[Math.floor(Math.random() * hiringTypes.length)];
  };

  // Function to generate random CTC
  const getRandomCTC = () => {
    return Math.floor(Math.random() * (1500000 - 400000 + 1)) + 400000; // Random salary between 4,00,000 and 15,00,000
  };

  // Create custom data array
  const rows = Array.from({ length: 15 }, (_, index) => ({
    id: index + 1,
    name: getRandomName(),
    role: getRandomRole(),
    location: getRandomLocation(),
    generatedOn: getRandomDate(),
    hiringType: getRandomHiringType(),
    ctc: getRandomCTC(),
  }));

  // Define columns for DataGrid
  const columns = [
    { field: "hiringType", headerName: "Letter Type", flex: 1 },
    { field: "empid", headerName: "Emp Id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "rating", headerName: "Rating", flex: 1 },
    { field: "newposition", headerName: "New Position", flex: 1 },
    { field: "currentctc", headerName: "Current CTC", flex: 1 },
    { field: "revisedctc", headerName: "Revised CTC", flex: 1 },
    {
      field: "action",
      headerName: "Preview/Modify",
      flex: 1,
      renderCell: renderPreviewButton,
    },
  ];

  return (
    <>
      <Headerbar />
      <Grid className="il-pending-actions">
        <Grid className="grid-container-main">
          <Grid container className="grid-container">
            <Grid item xs={6} md={12} className="data-grid-table">
              <DataGrid
                rows={rows}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: null,
                }}
                hideFooterPagination // Hide footer pagination controls
                hideFooter // Hide footer
                checkboxSelection // Enable checkbox selection
              />
            </Grid>
          </Grid>
          <Grid container className="data-grid-buttons">
            <Grid item xs={8} className="button button0">
              {/* <Button variant="contained">Contained</Button> */}
            </Grid>
            <Grid item xs={4} container className="button-group">
              <Grid item xs={"auto"} className="button button1">
                <Button variant="outlined" color="success">
                  Approve
                </Button>
              </Grid>
              {/* <Grid item xs={"auto"} className="button button2">
                <Button variant="outlined" color="success">
                  Download
                </Button>
              </Grid> */}
              <Grid item xs={"auto"} className="button button3">
                <Button variant="outlined" color="error">
                  Revoke
                </Button>
              </Grid>
              <Grid item xs={"auto"} className="button button4">
                <GreyButton variant="outlined">Delete</GreyButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
