import React, { useState, useEffect } from "react";
import axios from "axios";
import Headerbar from "../../components/headerbar/Headerbar";
import Modal from "react-modal"; // Import the Modal component
import "./CustomizeOfferLetter.scss";

// Set the app element for accessibility
Modal.setAppElement('#root');


const CustomizeOfferLetter = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [addTemplateModalIsOpen, setAddTemplateModalIsOpen] = useState(false); // Separate state for Add Template modal
  const [customTemplateModalIsOpen, setCustomTemplateModalIsOpen] = useState(false); // Separate state for Custom Template modal
  const [letterType, setLetterType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [sampleGenerateModalIsOpen, setSampleGenerateModalIsOpen] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false); // State for Preview Modal
  const [templates, setTemplates] = useState([]);

useEffect(() => {
  axios.get(`${process.env.REACT_APP_BASE_URL}/get-id-and-templates`)
    .then(response => {
      console.log(response.data);
      setTemplates(response.data);  // Assuming the API returns the correct template list with IDs
    })
    .catch(error => {
      console.error("Error fetching templates:", error);
    });
}, []);
  
// Handle file selection
const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
};

// Handle template selection
const handleTemplateChange = (event) => {
  const selectedTemplateId = parseInt(event.target.value);
  setSelectedTemplateId(selectedTemplateId);
};

// Handle sample generate - Open Modal
const handleSampleGenerate = () => {
  setSampleGenerateModalIsOpen(true); // Open the modal
};

// Add Template
const handleAddTemplate = () => {
  // Ensure that all fields are filled and a file is selected
  if (!selectedFile || !letterType || !templateName) {
    alert("Please fill in all fields and select a file!");
    return;
  }

  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("letter_type", letterType);
  formData.append("template_name", templateName);

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/add-template`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      alert("Template uploaded successfully!");
      setAddTemplateModalIsOpen(false); // Close the modal after successful upload
      // Reset form fields after submission
      setSelectedFile(null);
      setLetterType("");
      setTemplateName("");
    })
    .catch((error) => {
      console.error("Error uploading template:", error);
    });
};

// Custom Template
// Custom Template functionality
const handleCustomTemplate = () => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/get-templates`) // Fetch the templates
    .then((response) => {
      setTemplates(response.data);  // Assuming the response contains the template data
      setCustomTemplateModalIsOpen(true);  // Open the modal to display templates
    })
    .catch((error) => {
      console.error("Error fetching templates:", error);
    });
};

// Function to handle selecting and editing a specific template
const handleEditTemplate = (templateName) => {
  console.log("Editing template:", templateName);
  
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/custom-template/${templateName}`)
    .then((response) => {
      if (response.data.preview_url) {
        const docxUrl = response.data.preview_url;

        // Use Office Online to open the document for editing
        const officeOnlineUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(docxUrl)}`;
        
        // Open the document in Office Online in a new tab
        window.open(officeOnlineUrl, "_blank");
      } else {
        console.error("Preview URL not found");
      }
    })
    .catch((error) => {
      console.error("Error editing template:", error);
    });
};

// Preview Template
// Fetch templates from the database on modal open
const handleOpenPreviewModal = () => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/get-id-and-templates`)
    .then((response) => {
      setTemplates(response.data);  // Assuming the response contains the template data
      setPreviewModalIsOpen(true);
    })
    .catch((error) => {
      console.error("Error fetching templates:", error);
    });
};

const handlePreviewTemplate = (templateName) => {
  console.log("Previewing template:", templateName);

  axios
      .get(`${process.env.REACT_APP_BASE_URL}/preview-template/${templateName}`)
      .then((response) => {
          if (response.data.preview_url) {
              const previewURL = response.data.preview_url;
              window.open(previewURL, "_blank");  // Open the URL in a new tab
          } else {
              console.error("Preview URL not found");
          }
      })
      .catch((error) => {
          console.error("Error previewing template:", error);
      });
};

// Handle generating the PDF offer letter
const handleGenerateOfferLetter = () => {
  if (!selectedFile || !selectedTemplateId) {
    alert("Please select a template and upload an Excel file!");
    return;
  }

  const formData = new FormData();
  formData.append("employee_file", selectedFile);
  formData.append("template_id", selectedTemplateId);  // Send template_id

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/generate-sample-offer-letter`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob" // Ensure response type is blob to handle file download
    })
    .then((response) => {
      // Create a URL for the blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "offer_letter.pdf");  // Adjust the filename as needed
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error generating offer letter:", error);
    });
};

// Add Excel
const handleAddExcel = () => {
  if (!selectedFile) {
    alert("Please select a file first!");
    return;
  }

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/add-excel`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("Excel file uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error uploading Excel file:", error);
      });
  };

// Download Excel
const handleDownloadExcel = () => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/download-excel`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.xlsx");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading Excel file:", error);
    });
};

return (
  <>
    <Headerbar />
    <div className="main-container">
      <div className="button-grid">
        <button className="custom-button" onClick={() => setAddTemplateModalIsOpen(true)}>
          <div className="button-text">
            Add<br />Template
          </div>
          <div className="circle-icon">+</div>
        </button>

        <button className="custom-button" onClick={handleCustomTemplate}>
          <div className="button-text">
            Custom<br />Template
          </div>
          <div className="circle-icon">+</div>
        </button>

        <button className="custom-button" onClick={handleOpenPreviewModal}>
          <div className="button-text">
            Preview<br />Template
          </div>
          <div className="circle-icon">+</div>
        </button>

        <button className="custom-button" onClick={handleSampleGenerate}>
          <div className="button-text">
            Sample<br />Generate
          </div>
          <div className="circle-icon">+</div>
        </button>

        <button className="custom-button" onClick={() => document.getElementById('excel-input').click()}>
          <div className="button-text">
            Add<br />Excel
          </div>
          <div className="circle-icon">+</div>
        </button>
        <input
          type="file"
          id="excel-input"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <button className="custom-button" onClick={handleDownloadExcel}>
          <div className="button-text">
            Download<br />Excel
          </div>
          <div className="circle-icon">+</div>
        </button>
      </div>

    {/* Add Template Modal */}
    <Modal
      isOpen={addTemplateModalIsOpen}
      onRequestClose={() => setAddTemplateModalIsOpen(false)}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="modal-content">
        <h2>Add Template</h2>
        <label>Letter Type:</label>
        <input type="text" value={letterType} onChange={(e) => setLetterType(e.target.value)} />
        <label>Template Name:</label>
        <input type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
        <label className="green-file-upload">Upload File:</label>
        <input type="file" onChange={handleFileChange} />

        <div className="modal-buttons">
          <button className="submit-button" onClick={handleAddTemplate}>Submit</button>
          <button className="cancel-button" onClick={() => setAddTemplateModalIsOpen(false)}>Cancel</button>
        </div>
      </div>
    </Modal>

    {/* Preview Template Modal */}
    <Modal
        isOpen={previewModalIsOpen}
        onRequestClose={() => setPreviewModalIsOpen(false)}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
    >
      <div className="modal-content">
        <button className="close-button" onClick={() => setPreviewModalIsOpen(false)}>×</button>  {/* Updated Close Button */}
        <h2>Preview Template</h2>
        <ul className="template-list">
          {templates.map((template, index) => (
            <li key={index}>
              <button className="template-button" onClick={() => handlePreviewTemplate(template.template_name)}>{template.template_name}</button>
            </li>
          ))}
        </ul>
      </div>
    </Modal>

    {/* Custom Template Modal */}
    <Modal
      isOpen={customTemplateModalIsOpen}
      onRequestClose={() => setCustomTemplateModalIsOpen(false)}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="modal-content">
        <button className="close-button" onClick={() => setCustomTemplateModalIsOpen(false)}>×</button>
        <h2>Select Template to Edit</h2>
        <ul className="template-list">
          {templates.map((template, index) => (
            <li key={index}>
              <button className="template-button" onClick={() => handleEditTemplate(template.name)}>
                {template.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
    
    {/* Sample Generate Modal */}
    <Modal
      isOpen={sampleGenerateModalIsOpen}
      onRequestClose={() => setSampleGenerateModalIsOpen(false)}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="modal-content">
        <h2>Generate Offer Letter</h2>

        <label>Select Template:</label>
        <select
          value={selectedTemplateId}
          onChange={handleTemplateChange}  // Update this to use template_id
        >
          <option value="" disabled hidden>Select Template</option>
          {templates.map((template) => (
            <option key={template.template_id} value={template.template_id}>
              {template.template_name}
            </option>
          ))}
        </select>

        <label>Upload Excel File:</label>
        <input type="file" accept=".csv,.xls,.xlsx" onChange={handleFileChange} />

        <div className="modal-buttons">
          <button className="submit-button" onClick={handleGenerateOfferLetter}>Generate</button>
          <button className="cancel-button" onClick={() => setSampleGenerateModalIsOpen(false)}>Cancel</button>
        </div>
      </div>
    </Modal>
    </div>
  </>
);
};

export default CustomizeOfferLetter;

