import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import toaster from "../../../utility/toaster/toaster";
import Headerbar from "../../components/headerbar/Headerbar";
import "./GenerateOfferLetter.scss";
import { useEffect } from "react";

const GenerateOfferLetter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateChoice, setTemplateChoice] = useState("");
  const [templates, setTemplates] = useState([]);

  // Fetch templates from the API
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-id-and-templates`)
      .then((response) => {
        setTemplates(response.data); // Assuming the API returns the list of templates
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  }, []);

  const isValidFile = (file) => {
    const validExtensions = [".xls", ".xlsx", ".csv"];
    const fileName = file.name.toLowerCase();
    return validExtensions.some((ext) => fileName.endsWith(ext));
  };

  const handleSubmit = async (event, url) => {
    event.preventDefault();

    if (!selectedFile && !templateChoice) {
      toast.error("Please select a template and upload a file (.xls or .xlsx).");
      return;
    }

    if (!templateChoice) {
      toast.error("Please select a template");
      // return;
    }
    if (!selectedFile) {
      toast.error("Please upload a file (.xls or .xlsx).");
      return;
    }


    if (!isValidFile(selectedFile)) {
      toast.error("Please upload a valid Excel file (.xls or .xlsx).");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("template_choice", templateChoice);
    formData.append("employee_file", selectedFile);

    try {
      toast.info("Generating Offer Letter, please wait...", {
        autoClose: false,
        toastId: "loadingToast",
      });

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
      console.log("responseee ", response)
      if (response.status === 201) {
        toast.success("Offer Letter Generated Successfully");
        // const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.setAttribute("download", "OfferLetter.pdf");
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
      } else {
        toast.error("Offer Letter Generation Failed");
      }
    } catch (error) {
      toaster("error", "Offer Letter Generation Failed");
      console.error("Offer Letter Generation Failed", error);
    } finally {
      setIsLoading(false);
      toast.dismiss("loadingToast");
    }
  };

  return (
    <>
      <Headerbar />
      <div className="main-container">
        <Grid container spacing={3} className="generate-offer-letter">
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Typography variant="h5" align="center"  >
              Generate Offer Letter
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="template-choice-label">Choose Template</InputLabel>
              <Select
                labelId="template-choice-label"
                id="template_choice"
                className="template-choice"
                name="template_choice"
                value={templateChoice}
                onChange={(e) => setTemplateChoice(e.target.value)}
                required
                label="Choose Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template.template_id} value={template.template_id}>
                    {template.template_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className="upload-csv-btn"
              component="label"
              fullWidth
              endIcon={<FileUploadOutlinedIcon fontSize="large" color="success" />}
            >
              {selectedFile ? (
                <div className="file-name-container">
                  <span>
                    {selectedFile.name.length > 18
                      ? selectedFile.name.substring(0, 18) + "..."
                      : selectedFile.name}
                  </span>
                </div>
              ) : (
                "Upload File (CSV/Excel)"
              )}
              <input
                type="file"
                hidden
                name="employee_file"
                accept=".xlsx,.xls"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Button>
          </Grid>
          <Grid item xs={12} container spacing={2} justifyContent={"center"} >
            {/* <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#96C9F4", color: "black" }}
                disabled={isLoading}
                onClick={(e) => handleSubmit(e, `${process.env.REACT_APP_BASE_URL}/preview-offer-letter`)}
                fullWidth
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
              >
                Preview
              </Button>
            </Grid> */}
            <Grid item xs={6} sm={4}>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#0F67B1" }}
                fullWidth
                onClick={(e) => handleSubmit(e, `${process.env.REACT_APP_BASE_URL}/generate-offer-letter`)}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div >
    </>
  );
};

export default GenerateOfferLetter;
