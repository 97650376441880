import { useState } from "react";
import SideBarContext from "./SidebarContext";

const SidebarState = (props) => {
  const [context, setContext] = useState();
  return (
    <SideBarContext.Provider value={{ context, setContext }}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export default SidebarState;
