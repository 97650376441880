import { Button, Grid } from "@mui/material";
import "./headerbar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import profile_image from "../../../assets/images/rc-profile-image.PNG";
import SimpleDialog from "../dialog/dialog";
import { useNavigate } from "react-router-dom";
import toaster from "../../../utility/toaster/toaster";
// import SideBarContext from "../../context/sidebarOpen/SidebarContext";
import debounce from "lodash/debounce";
import LogoutIcon from "@mui/icons-material/Logout";
 
export default function Headerbar({
  searchFlag,
  pendingApprovalData,
  pendingRecentActivityData,
  setPendingApprovaFilteredData,
  setFilteredRecentActivityData,
  setSearchVal,
  card,
}) {
  // const { menuDisplay, setMenuDisplay } = useContext(SideBarContext);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [username, setUsername] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const navigate = useNavigate();
 
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUsername(user.username);
      setProfileImage(user.imgUrl);
    }
  }, []);
  //Logout Functionality
  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("user");
 
    toaster("success", "Logged out Successfully");
    setOpen(false);
    setTimeout(() => {
      navigate("/login");
    }, 500);
  };
 
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    console.log("called");
    setSearchTerm(searchTerm);
    setSearchVal(searchTerm);
 
    const debouncedSearch = debounce((searchTerm) => {
      const filteredData = pendingApprovalData?.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setPendingApprovaFilteredData(filteredData);
      const filteredData2 = pendingRecentActivityData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredRecentActivityData(filteredData2);
    }, 200);
    debouncedSearch(searchTerm);
  };
 
  useEffect(() => {
    if (searchTerm) {
      const filteredData = pendingApprovalData?.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setPendingApprovaFilteredData(filteredData);
      const filteredData2 = pendingRecentActivityData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredRecentActivityData(filteredData2);
    }
  }, [
    card,
    pendingApprovalData,
    pendingRecentActivityData,
    searchTerm,
    setFilteredRecentActivityData,
    setPendingApprovaFilteredData,
  ]);
 
  return (
    <>
      <Grid
        container
        className="header-main"
        style={
          searchFlag
            ? { justifyContent: "space-between" }
            : { justifyContent: "flex-end" }
        }
      >
        {/* <Grid direction="column" className="header-subgrid">
          <h3>{title}</h3>
          <span>{date}</span>
        </Grid> */}
        {searchFlag && (
          <Grid className="search-bar" alignItems={"center"}>
            <Grid className="search-box">
              <Grid>
                {/* <SmartToyIcon /> */}
                <i className="fa-solid fa-robot"></i>
                <input
                  placeholder="Hey! What can I help you with?"
                  className="search"
                  autoComplete="off"
                  onChange={handleSearchChange}
                  autoCapitalize="off"
                  autoFocus="off"
                  type="text"
                  value={searchTerm}
                  name="search"
                ></input>
              </Grid>
              {/* <SearchBar /> */}
              <SearchIcon fontSize="small" />
            </Grid>
          </Grid>
        )}
        <Grid className="profile-div">
          <Grid className="sidebar-footer">
            <Grid className="menu-div">
              <span>{username || "User"}</span>
              <img
                src={profileImage || "path/to/default/image.jpg"}
                alt="profile_image"
                style={{ cursor: "pointer" }}
              ></img>
              <LogoutIcon
                fontSize={"medium"}
                style={{ cursor: "pointer" }}
                onClick={() => setOpen(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SimpleDialog
        open={open}
        setOpen={setOpen}
        content={
          <form onSubmit={(event) => handleLogout(event)}>
            <Grid className="logout-main">
              <span>Are you sure you want to Logout?</span>
              <Grid>
                <Button type="submit">Yes</Button>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
        }
        executeFlag={false}
      />
    </>
  );
}