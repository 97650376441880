import { Button, FormControl, Grid, InputAdornment, InputLabel, Stack, TextField } from "@mui/material";
import "./Login2.scss";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import toaster from "../../../../utility/toaster/toaster";
import Carousel from "../../../components/carousel/carousal";
import axios from "axios"; // Import Axios
import { postRequest } from "../../../../services/api/axios_api_Request";
 
export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const modules = ["Dashboard", "SOW Overview", "Resources Overview"];
  const [index, setIndex] = useState(0);
  const [content, setContent] = useState("Enter your credential to access your account");
 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await postRequest("login", { email, password });
 
      if (response.status === 200) {
        const { username, imgUrl } = response.data; // Extract username and imgUrl from API response
 
        localStorage.setItem("user", JSON.stringify({username, imgUrl }));
        toaster("success", "Login Successful.");
        setTimeout(() => {
          navigate("/");
        }, 150);
      } else {
        toaster("error", "Invalid credentials!");
      }
    } catch (error) {
      toaster("error", "Login failed! Please try again.");
    }
  };
 
  useEffect(() => {
    const cookieDetail = document.cookie;
    const cookie = cookieDetail.split(";");
    const isUserData = cookie.find((c) => c.trim().startsWith("rememberMe="));
    if (isUserData) {
      const encodedJson = isUserData.split("rememberMe=")[1];
      const decodedJson = decodeURIComponent(encodedJson);
      const userDetail = JSON.parse(decodedJson);
      const { email, password } = userDetail;
      setEmail(email);
      setPassword(password);
    }
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % modules.length);
    }, 3000);
 
    return () => {
      clearInterval(intervalId);
    };
  }, [modules]);
 
  useEffect(() => {
    setContent(modules[index]);
  }, [index, modules]);
 
  return (
    <Grid className="login-main-container">
      <Grid className="login-main-inner">
        <Grid className="login-main-left">
          <Grid className="login-main-left-inner">
            <Grid className="login-main-left-head">
              <h4>
              Effortless Letter Creation with Pace, Accuracy, and Professionalism for Every Need <strong></strong>
              </h4>
              <p>{content}</p>
            </Grid>
            <Grid className="login-main-left-body">
              <Grid className="carousel-container">
                <Carousel />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="login-main-right">
          <Grid className="login-main-right-inner">
            <Grid className="login-main-right-form-head">
              <h1>PaceLetter Generator</h1>
            </Grid>
            <Grid className="login-main-right-form-body">
              <Grid className="login-form-wrapper">
                <form onSubmit={handleSubmit}>
                  <Stack spacing={5}>
                    <Grid className="form-heading">
                      <h2>Login</h2>
                      <p>
                        Don't have an account <span className="blink">Register Now</span>
                      </p>
                    </Grid>
                    <Grid className="form-field">
                      <InputLabel className="input-label" htmlFor="email-form-control" shrink={true}></InputLabel>
                      <TextField
                        name="email"
                        value={email}
                        id="email-form-control"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Enter Username"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid className="form-field">
                      <FormControl id="password-form-control" variant="outlined">
                        <TextField
                          className="password-input"
                          name="password"
                          label="Password"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Enter password"
                          id="outlined-password-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  className="toggle-eye"
                                  onClick={togglePasswordVisibility}
                                  style={{ cursor: "pointer" }}>
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                      <Grid className="forgot-rememberMe">
                        {/* <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => setRememberMe(!rememberMe)}
                              />
                            }
                            label="Remember me"
                          /> */}
                        <span
                          className="forgot-text"
                          onClick={() => navigate("/forgotPassword")}
                          style={{ textDecoration: "underline" }}>
                          Forgot password?
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-button">
                      <Button type="submit" variant="contained" size="large" disabled={!email || !password}>
                        Login
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 18 12" fill="none">
                          <path
                            d="M9.67399 11.748C9.57332 11.6684 9.49345 11.5738 9.43895 11.4696C9.38445 11.3655 9.3564 11.2538 9.3564 11.141C9.3564 11.0282 9.38445 10.9166 9.43895 10.8124C9.49345 10.7083 9.57332 10.6137 9.67399 10.534L14.3072 6.85729L1.07979 6.85729C0.793409 6.85729 0.51876 6.76701 0.316261 6.60631C0.113762 6.44562 -2.7222e-07 6.22767 -2.62286e-07 6.0004C-2.52352e-07 5.77314 0.113762 5.55519 0.316261 5.39449C0.51876 5.2338 0.793409 5.14352 1.07979 5.14352L14.3072 5.14352L9.67399 1.46533C9.47114 1.30436 9.35718 1.08603 9.35718 0.858372C9.35718 0.630718 9.47114 0.412387 9.67399 0.251411C9.87684 0.0904352 10.152 -3.4065e-07 10.4388 -3.30509e-07C10.7257 -3.20368e-07 11.0008 0.0904353 11.2037 0.251411L17.6824 5.39273C17.7831 5.47234 17.8629 5.56693 17.9174 5.67109C17.9719 5.77524 18 5.88691 18 5.99969C18 6.11247 17.9719 6.22414 17.9174 6.3283C17.8629 6.43245 17.7831 6.52705 17.6824 6.60665L11.2037 11.748C11.1034 11.8279 10.9842 11.8912 10.8529 11.9345C10.7217 11.9777 10.581 12 10.4388 12C10.2967 12 10.156 11.9777 10.0248 11.9345C9.89351 11.8912 9.77431 11.8279 9.67399 11.748Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                    </Grid>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}