import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./dialog.scss";
import { CssBaseline } from "@mui/material";

export default function SimpleDialog(props) {
  const { open, setOpen, content, executeFlag } = props;

  const handleClose = (event, reason) => {
    if (executeFlag) {
      if (event.key === "Escape") {
        event.stopPropagation();
      } else if (reason !== "backdropClick") {
        setOpen(false);
      }
    } else setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <Dialog
        maxWidth={"lg"}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(5px)",
        }}
        open={open}
      >
        {content}
      </Dialog>
    </>
  );
}
