import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        display: true,
      },
      ticks: {
        font: {
          weight: "bold", // Make x-axis labels bold
        },
      },
    },
    y: {
      title: {
        display: true,
      },
      ticks: {
        font: {
          weight: "bold", // Make y-axis labels bold
        },
      },
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    tooltip: {
      mode: "index",
      intersect: false,
    },
  },
};

const BarChart = ({ data, position }) => {
  const chartData = {
    labels: data?.map((item) => item.year),
    datasets: [
      {
        label: position,
        data: data?.map((item) => item.count),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "450px" }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChart;
