import React, { useEffect, useState } from "react"
import Headerbar from "../../components/headerbar/Headerbar"
import "./Dashboard.scss"
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import MultiLineGraph from "../../components/charts/MultiLineChart"

import { getRequest } from "../../../services/api/axios_api_Request"
import DraftsIcon from "@mui/icons-material/Drafts";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WorkIcon from "@mui/icons-material/Work";
import BarChart from "../../components/charts/BarChart"

export default function Dashboard() {
  const [uniquePositions, setUniquePositions] = useState([])
  const [chartSelectedPosition, setChartSelectedPosition] = useState("all")
  const [lineChartYear, setLineChartYear] = useState("2023")
  const [lineChartDepartmentFilter, setLineChartDepartmentFilter] = useState({
    dept1: "Tech - Data Practice",
    dept2: "Tech - Product Development",
  })
  const [searchVal, setSearchVal] = useState("")
  const [filteredPendingApprovaData, setPendingApprovaFilteredData] = useState("")
  const [filteredRecentActivityData, setFilteredRecentActivityData] = useState("")
  const [cardCount, setCardCount] = useState({
    "total-offer-letters": 0,
    "total-increment-letters": 0,
    "total-promotion-letters": 0,
  })
  const [cardYear, setCardYear] = useState({
    "total-offer-letters": "all",
    "total-increment-letters": "all",
    "total-promotion-letters": "all",
  })
  const [multiLineData, setMultiLineData] = useState()
  const [barchartData, setBarchartData] = useState([])
  const [pendingApprovalData, setPendingApprovalData] = useState()
  const [pendingRecentActivityData, setPendingRecentActivityData] = useState()

  const getBarChartData = async (position) => {
    try {
      const res = await getRequest("/dashboard/bar-chart", {
        positionLevel: position,
      })
      let data = res?.data

      setBarchartData(data)
    } catch (error) {
      console.log("error fetching bar chart data", error)
    }
  }

  useEffect(() => {
    getBarChartData(chartSelectedPosition)
  }, [chartSelectedPosition])

  const getUniquePosition = async () => {
    try {
      const res = await getRequest("/unique-positions")
      const data = res?.data
      console.log("unique positions : ", data)
      setUniquePositions(data)
    } catch (error) {
      console.log("error fetching unique positions", error)
    }
  }

  const getCardCount = async (field) => {
    try {
      console.log(cardYear, "chinmay")
      const res = await getRequest(`/dashboard/${field}`, {
        selectedYear: cardYear[field],
      })
      console.log(res, "chinmay")
      if (res?.status === 200) {
        setCardCount((prevVal) => {
          return {
            ...prevVal,
            [field]: res?.data[0]?.count,
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCardCount("total-offer-letters")
    getCardCount("total-increment-letters")
    getCardCount("total-promotion-letters")
  }, [cardYear])

  const thead = [
    { id: "emp_name", label: "Name", width: "30%" },
    { id: "letter_type", label: "Letter Type", width: "30%" },
    { id: "level", label: "Role", width: "30%" },
  ]

  const thead2 = [
    { id: "emp_name", label: "Name", width: "30%" },
    { id: "letter_type", label: "Letter Type", width: "30%" },
    { id: "letter_status", label: "Letter Status", width: "30%" },
  ]

  // function selectColorChange(id) {
  //   const classList = document.getElementsByClassName("card");
  //   for (let key of classList) {
  //     key.style.borderBottom = "none";
  //     key.style.paddingBottom = "20px";
  //   }
  //   const element = document.getElementById(id);
  //   if (id === "Expiring SOW") {
  //     element.style.borderBottom = "5px solid #b72428";
  //     element.style.paddingBottom = "15px";
  //   } else {
  //     element.style.borderBottom = "5px solid #5F9EC8";
  //     element.style.paddingBottom = "15px";
  //   }
  // }

  const get_pending_approval_List = async () => {
    try {
      const res = await getRequest("/dashboard/pending-approval", {})
      console.log(res, "chinmay")
      if (res?.status === 200) {
        const finaldata = res?.data?.map((item) => {
          const reorderedItem = {}
          ;["name", "letter_type", "position_level"].forEach((key) => {
            reorderedItem[key] = item[key]
          })
          return reorderedItem
        })
        setPendingApprovalData(finaldata)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const get_Recent_Activity_List = async () => {
    try {
      const res = await getRequest("/dashboard/recent-activities", {})
      console.log(res, "chinmay")
      if (res?.status === 200) {
        const finaldata = res?.data?.map((item) => {
          const reorderedItem = {}
          ;["name", "letter_type", "letter_status"].forEach((key) => {
            reorderedItem[key] = item[key]
          })
          return reorderedItem
        })
        setPendingRecentActivityData(finaldata)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUniquePosition()
    get_pending_approval_List()
    get_Recent_Activity_List()
  }, [])

  const getMultiLineChartData = async () => {
    try {
      const res1 = await getRequest("/dashboard/line-chart", {
        selectedDepartment: lineChartDepartmentFilter.dept1,
        selectedYear: lineChartYear,
      })
      const months = res1?.data?.map((item) => item.month_name)
      console.log(res1, "res1", months)
      const res2 = await getRequest("/dashboard/line-chart", {
        selectedDepartment: lineChartDepartmentFilter.dept2,
        selectedYear: lineChartYear,
      })
      console.log(res2, "res2")
      if (res1?.status === 200 && res2?.status === 200) {
        setMultiLineData({
          data1: res1?.data,
          data2: res2?.data,
          months: months,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMultiLineChartData()
  }, [lineChartDepartmentFilter, lineChartYear])

  return (
    <>
      <Headerbar
        title={"Dashboard"}
        searchFlag={true}
        pendingApprovalData={pendingApprovalData}
        pendingRecentActivityData={pendingRecentActivityData}
        setSearchVal={setSearchVal}
        setPendingApprovaFilteredData={setPendingApprovaFilteredData}
        setFilteredRecentActivityData={setFilteredRecentActivityData}
      />
      <Grid className="Dashboard-main">
        <Grid className="Dashboard-inner">
          <Grid className="inner-1">
            <Grid
              className="card"
              id="total-offer-letters"
              // onClick={() => {
              //   setCard("All SOW");
              //   selectColorChange("All SOW");
              // }}
              style={{
                cursor: "pointer",
                // borderBottom: "5px solid #5F9EC8",
                // paddingBottom: "15px",
              }}>
              <Grid className="inner-left">
                <Grid className="text-box">
                  <h3>{cardCount["total-offer-letters"]}</h3>
                  <h4>Total Offer Letters</h4>
                </Grid>
                <Grid className="content-box">
                  <Grid className="icon-card">
                    <DraftsIcon fontSize="large" sx={{ color: "#5F9EC8" }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="inner-right">
                <select
                  defaultValue={"all"}
                  onChange={(event) => {
                    setCardYear(() => {
                      return {
                        "total-offer-letters": event.target.value,
                        "total-increment-letters": event.target.value,
                        "total-promotion-letters": event.target.value,
                      }
                    })
                  }}>
                  <option value={"all"}>Year</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </Grid>
            </Grid>
            <Grid className="card" id="total-increment-letters" style={{ cursor: "pointer" }}>
              <Grid className="inner-left">
                <Grid className="text-box">
                  <h3>{cardCount["total-increment-letters"]}</h3>
                  <h4>Total Increment Letters</h4>
                </Grid>
                <Grid className="content-box">
                  <Grid className="icon-card">
                    <TrendingUpIcon fontSize="large" sx={{ color: "#5F9EC8" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="card"
              style={{ cursor: "pointer" }}
              id="total-promotion-letters"
              // onClick={() => {
              //   setCard("Upcoming SOW");
              //   selectColorChange("Upcoming SOW");
              // }}
            >
              <Grid className="inner-left">
                <Grid className="text-box">
                  <h3>{cardCount["total-promotion-letters"]}</h3>
                  <h4>Total Promotions</h4>
                </Grid>
                <Grid className="content-box">
                  <Grid className="icon-card">
                    <WorkIcon fontSize="large" sx={{ color: "#5F9EC8" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
          <Grid className="inner-2">
            <Grid className="table-grid">
              <Grid className="heading-box">
                <h4>Pending for Approval</h4>
                {/* <select
                  style={{
                    cursor: "pointer",
                    border: "1px solid #f2f2f2",
                  }}
                  defaultValue={expiryFilter}
                  onChange={(event) => setExpiryFilter(event.target.value)}
                >
                  <option value="Week">Topic: Week</option>
                  <option value="Month">Topic: Month</option>
                </select> */}
              </Grid>
              <Grid className="table-box">
                <Table style={{ width: "100%" }}>
                  {/* Pending for approval table */}
                  <TableHead
                    style={{
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                    }}>
                    <TableRow>
                      {thead
                        ?.filter((item) => item.label !== "Status")
                        ?.map((item, index) => (
                          <TableCell> {item.label} </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {((searchVal ? filteredPendingApprovaData : pendingApprovalData) || [])?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          {Object.keys(item)
                            ?.filter((item) => item !== "status")
                            ?.map((rowKey, ind) => {
                              return (
                                <TableCell key={ind} className="rev-data">
                                  {item[rowKey]}
                                </TableCell>
                              )
                            })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid className="chart-div">
              {/* <h4>Letter Analysis</h4> */}
              <Grid className="chart-div-revenue">
                <Grid>
                  <select
                    className="dept1"
                    name="dept1"
                    value={lineChartDepartmentFilter.dept1}
                    onChange={(event) => {
                      setLineChartDepartmentFilter((prevVal) => {
                        return {
                          ...prevVal,
                          dept1: event.target.value,
                        }
                      })
                    }}>
                    <option value="Tech - Data Practice">Tech - Data Practice</option>
                    <option value="Tech - Business Intelligence">Tech - Business Intelligence</option>
                    <option value="Tech - Product Development">Tech - Product Development</option>
                  </select>
                  <select
                    className="dept2"
                    name="dept2"
                    value={lineChartDepartmentFilter.dept2}
                    onChange={(event) => {
                      console.log(event.target.value, "chinmy")
                      setLineChartDepartmentFilter((prevVal) => {
                        return {
                          ...prevVal,
                          dept2: event.target.value,
                        }
                      })
                    }}>
                    <option value="Tech - Data Practice">Tech - Data Practice</option>
                    <option value="Tech - Business Intelligence">Tech - Business Intelligence</option>
                    <option value="Tech - Product Development">Tech - Product Development</option>
                  </select>
                  <select
                    className="year"
                    name="year"
                    value={lineChartYear}
                    style={{ width: "4.5rem" }}
                    onChange={(event) => {
                      setLineChartYear(event.target.value)
                    }}>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                </Grid>
                <MultiLineGraph  data={multiLineData} labels={lineChartDepartmentFilter}></MultiLineGraph>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="inner-2">
            <Grid className="table-grid">
              <Grid className="heading-box">
                <h4>Recent Activites</h4>
                {/* <select
                  style={{
                    cursor: "pointer",
                    border: "1px solid #f2f2f2",
                  }}
                  defaultValue={expiryFilter}
                  onChange={(event) => setExpiryFilter(event.target.value)}
                >
                  <option value="Week">Topic: Week</option>
                  <option value="Month">Topic: Month</option>
                </select> */}
              </Grid>
              <Grid className="table-box">
                <Table style={{ width: "100%" }}>
                  {/* Recent activities table */}
                  <TableHead
                    style={{
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                    }}>
                    <TableRow>
                      {thead2
                        ?.filter((item) => item.label !== "Role")
                        ?.map((item, index) => (
                          <TableCell> {item.label} </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {((searchVal ? filteredRecentActivityData : pendingRecentActivityData) || [])?.map(
                      (item, index) => {
                        return (
                          <TableRow key={index}>
                            {Object.keys(item)
                              ?.filter((item) => item !== "role")
                              ?.map((rowKey, ind) => {
                                return (
                                  <TableCell key={ind} className="rev-data">
                                    {item[rowKey]}
                                  </TableCell>
                                )
                              })}
                          </TableRow>
                        )
                      }
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid className="chart-div">
              <Grid className="chart-div-head">
                <Grid className="inner-right">
                  <select defaultValue={"All"} onChange={(event) => setChartSelectedPosition(event.target.value)}>
                    Position
                    <option value={"All"}> All </option>
                    {Array.isArray(uniquePositions) &&
                      uniquePositions?.map((item, index) => {
                        return (
                          <option value={item.position_level} key={index}>
                            {" "}
                            {item.position_level}{" "}
                          </option>
                        )
                      })}
                  </select>
                </Grid>
              </Grid>
              <Grid className="chart-div-revenue">
                <BarChart data={barchartData} position={chartSelectedPosition}></BarChart>{" "}
                {/* <LineChart data={chartData} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
