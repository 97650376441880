import React from "react";
import { Routes, Route } from "react-router-dom";
import PublicRouter from "./PublicRouter";
import Login2 from "../app/modules/authentication/login-page/Login2";
import PrivateRouter from "./PrivateRouter";
import Dashboard from "../app/modules/dashboard/Dashboard";
// import SignUp from "../app/modules/authentication/signup-page/SignUp";
// import ForgotPassword from "../app/modules/authentication/forgot-password/ForgotPassword";
import GenerateOfferLetter from "../app/modules/GenerateOfferLetter/GenerateOfferLetter";
import { OLPendingActions } from "../app/modules/OLPendingActions/OLPendingActions";
import { OLProcessedActions } from "../app/modules/OLProcessesdActions/OLProcessedActions";
import { ILPendingActions } from "../app/modules/ILPendingActions.jsx/ILPendingActions";
import CustomizeOfferLetter from "../app/modules/CustomizeOfferLetter/CustomizeOfferLetter";
export default function MainRoute() {
  return (
    <Routes>
      <Route path="/" element={<PublicRouter />}>
        <Route path="/login" element={<Login2 />} />
      </Route>
      {/* private router  */}
      <Route path="/" element={<PrivateRouter />}>
        <Route index element={<Dashboard />} />
        <Route
          path="/generate-offer-letter"
          element={<GenerateOfferLetter />}
        />
        <Route path="/OL-pending-actions" element={<OLPendingActions />} />
        <Route path="/OL-processed-actions" element={<OLProcessedActions />} />
        <Route path="/OL-customized" element={<CustomizeOfferLetter />} />
        <Route path="/IL-pending-actions" element={<ILPendingActions />} />
        <Route path="/*" element={<h4>Error 404</h4>} />
      </Route>
    </Routes>
  );
}