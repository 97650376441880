import React from "react";
import MainLayout from "../app/layouts/main-layout/MainLayout";
import { Navigate, Outlet } from "react-router-dom";
 
export default function PrivateRouter() {
  // Function to get a cookie by name
  const isLoggedIn = localStorage.getItem("user");
  const isAppID = 5;
 
  return (
    <>
      {isLoggedIn && isAppID === 5 ? (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}