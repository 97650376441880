import { Grid } from "@mui/material";
import "./main-layout.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useContext } from "react";
import SideBarContext from "../../context/sidebarOpen/SidebarContext";

export default function MainLayout({ children }) {
  const {menuDisplay, setMenuDisplay} = useContext(SideBarContext);

  return (
    <Grid
      className="mainLayout-container"
      onClick={() => {
        if (menuDisplay === "flex") {
          setMenuDisplay("none");
        }
      }}
    >
      <Sidebar />
      <Grid className="mainLayout-main-content">{children}</Grid>
    </Grid>
  );
}
