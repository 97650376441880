import { ActionType } from "../ActionTypes/ActionType";

const initialState = {
  isLoading: false,
  msg: "",
  clientNameData: [],
};

export const getClientNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_CLIENTNAME_PENDING:
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case ActionType.GET_CLIENTNAME_SUCCESS:
      return {
        ...state,
        clientNameData: action.payload,
        isLoading: false,
        msg: action.msg,
      };
    case ActionType.GET_CLIENTNAME_FAILURE:
      return {
        ...state,
        msg: action.msg,
        isLoading: false,
      };

    default:
      return state;
  }
};
