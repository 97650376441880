function formatToIndianRupees(amount) {
    // Ensure the amount is a number and has two decimal places
    let num = parseFloat(amount).toFixed(2);

    // Split the amount into the integer and decimal parts
    let [integerPart, decimalPart] = num.split('.');

    // Add commas to the integer part
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    // Insert commas in the remaining part
    if (otherDigits !== '') {
        lastThreeDigits = ',' + lastThreeDigits;
    }

    let formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    // Combine integer and decimal parts
    let formattedAmount = `₹ ${formattedIntegerPart}.${decimalPart}`;

    return formattedAmount;
}


module.exports = {
    formatToIndianRupees    
}