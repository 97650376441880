import React, { useState } from "react";
import "./sidebar.scss";
import { Divider, Grid, Stack } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EmailIcon from "@mui/icons-material/Email";
import { NavLink, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Collapse } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import paceLogo from "../../../assets/images/login-images/pace-logo.png";
 
// sidebar component
export default function Sidebar() {
  const [isCollapse, setCollapse] = useState(false);
  const [isCollapse2, setCollapse2] = useState(false);
 
  function Collapser() {
    if (isCollapse2) {
      setCollapse2(false);
    }
    setCollapse(!isCollapse);
  }
 
  function Collapser2() {
    if (isCollapse) {
      setCollapse(false);
    }
    setCollapse2(!isCollapse2);
  }
 
  const navigate = useNavigate();
 
  return (
    <>
      <Grid className="sidebar-main">
        <Grid className="sidebar-header">
          <img src={paceLogo} alt="pharma_ace_logo"></img>
          {/* <h4>Offer Letter Generator</h4> */}
        </Grid>
 
        <Grid className="sidebar-body">
          <Stack
            className="sidebar-body-list"
            style={{ paddingTop: "6.5px" }}
            divider={<Divider style={{ backgroundColor: "#ffffff" }} />}
          >
            <NavLink to={"/"}>
              <Grid>
                <HomeOutlinedIcon
                  fontSize="small"
                  style={{ color: "#ffffff" }}
                />
                <h5>HOME</h5>
              </Grid>
            </NavLink>
 
            <NavLink to="/generate-offer-letter">
              <Grid
                onClick={() => Collapser()}
                style={{
                  alignItems: "center",
                  color: "white",
                }}
              >
                <EmailIcon fontSize="small" style={{ color: "#ffffff" }} />
                <h5>OFFER LETTERS</h5>
                {/* {isCollapse ? (
                  <ExpandLess style={{ fontSize: "medium" }} />
                ) : (
                  <ExpandMore style={{ fontSize: "medium" }} />
                )} */}
              </Grid>
            </NavLink>
 
            <Collapse
              style={{ padding: 0 }}
              in={isCollapse}
              timeout="auto"
              unmountOnExit
            >
              <Stack style={{ padding: 0 }}>
                <NavLink to={"/generate-offer-letter"}>
                  <Grid style={{ gap: "6px" }}>
                    <AddCircleIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Generate</h5>
                  </Grid>
                </NavLink>
 
                <NavLink to={"/OL-pending-actions"}>
                  <Grid style={{ gap: "6px" }}>
                    <PendingActionsIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Pending for actions</h5>
                  </Grid>
                </NavLink>
 
                <NavLink to={"/OL-processed-actions"}>
                  <Grid style={{ gap: "6px" }}>
                    <AssignmentTurnedInIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Processed</h5>
                  </Grid>
                </NavLink>
 
 
                <NavLink to={"/OL-customized"}>
                  <Grid style={{ gap: "6px" }}>
                    <AssignmentTurnedInIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Customized</h5>
                  </Grid>
                </NavLink>
 
              </Stack>
            </Collapse>
 
            <NavLink to="/increment">
              <Grid
                style={{
                  alignItems: "center",
                  color: "white",
                }}
                onClick={() => Collapser2()}
              >
                <MoveToInboxIcon style={{ color: "#ffffff" }} />
                <h5>INCREMENT & PROMOTION</h5>
                {/* {isCollapse2 ? (
                  <ExpandLess style={{ fontSize: "medium" }} />
                ) : (
                  <ExpandMore style={{ fontSize: "medium" }} />
                )} */}
              </Grid>
            </NavLink>
 
            <Collapse
              style={{ padding: 0 }}
              in={isCollapse2}
              timeout="auto"
              unmountOnExit
            >
              <Stack style={{ padding: 0 }}>
                <NavLink to={"/xyz"}>
                  <Grid style={{ gap: "6px" }}>
                    <AddCircleIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Generate</h5>
                  </Grid>
                </NavLink>
 
                <NavLink to={"/IL-pending-actions"}>
                  <Grid style={{ gap: "6px" }}>
                    <PendingActionsIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Pending for Actions</h5>
                  </Grid>
                </NavLink>
 
                <NavLink to={"/increment"}>
                  <Grid style={{ gap: "6px" }}>
                    <AssignmentTurnedInIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                    <h5>Processed</h5>
                  </Grid>
                </NavLink>
              </Stack>
            </Collapse>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}