// import React from "react";
// import { Line } from "react-chartjs-2";
// import { Chart, registerables } from "chart.js";
// import { format } from "date-fns";

// Chart.register(...registerables);

// const chartOptions = {
//   responsive: true,
//   maintainAspectRatio: false,
//   scales: {
//     x: {
//       title: {
//         display: true,
//         text: "Months",
//       },
//     },
//     y: {
//       title: {
//         display: true,
//         text: "Offer Letters Generated",
//       },
//       beginAtZero: true,
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//       // position: "top",
//     },
//     tooltip: {
//       mode: "index",
//       intersect: false,
//     },
//   },
// };


// const MultiLineGraph = ({ data, labels }) => {
//   console.log(data, "dataaa")
//   // const months = getMonthNames(data?.months)
//   const chartData = {
//     labels: data?.months,
//     // labels: months,
//     datasets: [
//       {
//         label: labels.dept1,
//         data: data?.data1?.map((item) => item.count),
//         borderColor: "rgba(75,192,192,1)",
//         backgroundColor: "rgba(75,192,192,0.2)",
//       },
//       {
//         label: labels.dept2,
//         data: data?.data2?.map((item) => item.count),
//         borderColor: "rgba(153,102,255,1)",
//         backgroundColor: "rgba(153,102,255,0.2)",
   
//       },
//     ],
//   };

//   return (
//     <div style={{ width: "100%", height: "450px" }}>
//       <Line data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// export default MultiLineGraph;

import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const MultiLineGraph = ({ data, labels }) => {
  // Calculate the maximum value in the data
  const maxData1 = Math.max(...(data?.data1?.map((item) => item.count) || [0]));
  const maxData2 = Math.max(...(data?.data2?.map((item) => item.count) || [0]));
  const maxValue = Math.max(maxData1, maxData2);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: false, // Removed the "Months" title
        },
        ticks: {
          font: {
            weight: "bold", // Make x-axis values bold
          },
          callback: function (value, index, ticks) {
            // Shorten x-axis labels to the first three letters
            const label = this.getLabelForValue(value);
            return label ? label.slice(0, 3) : ''; // Show first 3 characters
          },
        },
      },
      y: {
        title: {
          display: true,
          font: {
            weight: "bold", // Make the y-axis label bold
            size: 14,
          },
        },
        ticks: {
          font: {
            weight: "bold", // Make y-axis values bold
          },
          beginAtZero: true,
          suggestedMax: maxValue + 2, // Ensure the y-axis is higher than the max value
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
  };

  const chartData = {
    labels: data?.months,
    datasets: [
      {
        label: labels.dept1,
        data: data?.data1?.map((item) => item.count),
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
      },
      {
        label: labels.dept2,
        data: data?.data2?.map((item) => item.count),
        borderColor: "rgba(153,102,255,1)",
        backgroundColor: "rgba(153,102,255,0.2)",
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "450px" }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default MultiLineGraph;

