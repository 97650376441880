import { ActionType } from "../Action-Type/ActionType";

const initialState = {
  isLoading: false,
  msg: "",
  overviewData: [],
};

export const getOverviewDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_OVERVIEW_PENDING:
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case ActionType.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewData: action.payload,
        isLoading: false,
        msg: action.msg,
      };
    case ActionType.GET_OVERVIEW_FAILURE:
      return {
        ...state,
        msg: action.msg,
        isLoading: false,
      };

    default:
      return state;
  }
};
