import React, { useState, useEffect, useCallback } from "react";
import "./carousal.scss";
import { Grid } from "@mui/material";
import image1 from "../../../assets/images/cimage1.PNG";
import image2 from "../../../assets/images/cimage2.PNG";
import image3 from "../../../assets/images/cimage3.PNG";

const Carousel = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [image1, image2, image3];

  const handleNext = useCallback(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentImage, handleNext]);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <>
      <Grid className="carousel-box">
        {images.map((item, index) => (
          <img
            key={index}
            className={`carousel-image ${
              index === currentImage ? "active" : ""
            }`}
            src={item}
            alt={`item ${index + 1}`}
          />
        ))}
      </Grid>

      <Grid className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImage ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Grid>
    </>
  );
};

export default Carousel;
